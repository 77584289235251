import React from "react"

import WorkPageLayout from "./Components/WorkPageLayout"
import { Box, Flex, Text } from "../../Components/Base"
import { WORK_ID, WORK } from "../../constant"

import Content from "./Components/Content"

const workId = WORK_ID.BETA_CONFERENCE
const workData = WORK.find(w => w.key === workId)
const ROOT_IMAGE_PATH = `/assets/work/${workId}`

const HeaderSection = () => {
  return (
    <Box>
      <Content.Header>{workData.name}</Content.Header>
      <Content.SubHeader>role: {workData.role}</Content.SubHeader>
      <Flex flexDirection={["column", "row"]} justifyContent={"space-between"}>
        <Content.HeaderParagraph width={"70%"}>
          In Beta Conference 2019, arranged by Skooldio, the team wanted to
          create an activity that create the connectivity among attendees.
          Therefore, the Beta Challenge game is created to let the attendees
          evaluate their skills and at the same time create the space that
          gather all attendees together.
          {/* <a
            href="https://beta-challenge.skooldio.com/"
            style={{ marginLeft: "8px" }}
          >
            here!
          </a> */}
        </Content.HeaderParagraph>
        {/* <Content.TextField
          textAlign={"right"}
          size={"small"}
          fields={[
            {
              field: "location",
              value: "Bangkok, Thailand",
            },
            { field: "program", value: "Sale Gallery" },
            { field: "client", value: "Advanced Living" },
            { field: "status", value: "completion 2017" },
            { field: "area", value: "400 sq.m." },
          ]}
        /> */}
      </Flex>
    </Box>
  )
}

const ContentSection = () => {
  return (
    <Content as={"article"}>
      <Content.Section>
        <Content.Image
          src={`/assets/work/${workId}/thumbnail.jpg`}
          width={"100%"}
          style={{
            objectFit: "cover",
            objectPosition: "center",
          }}
        />
      </Content.Section>

      <Content.Section title={"Features"}>
        <Content.Title>Web-view App</Content.Title>

        <Content.Paragraph>
          The game consists of two parts which are the web-view app and
          dashboard. The app works as a character generator which let the
          attendees to self-evaluate their skills through the set of questions
          and transform the results into a playful character which will be
          visualized as the animation on the dashboard.
        </Content.Paragraph>
        <Content.Image
          useSrcSet={true}
          src={`${ROOT_IMAGE_PATH}/app/app@3x.png`}
          width={"100%"}
        />
      </Content.Section>
      <Content.Section
        fullWidth={false}
        left={
          <>
            <Content.Title>Dashboard</Content.Title>
            <Content.Paragraph pr={5}>
              The dashboard digitally connect all characters together and the
              spaces around it physically gather all attendees together.
            </Content.Paragraph>
          </>
        }
        right={
          <Content.Image
            useSrcSet={true}
            src={`${ROOT_IMAGE_PATH}/dashboard/dashboard@3x.png`}
            style={{
              objectFit: "cover",
              objectPosition: "bottom",
            }}
          />
        }
      />
      <Content.Section>
        <Content.Image
          src={`/assets/work/${workId}/final-02.png`}
          caption={"the dashboard physically gather people"}
        />
      </Content.Section>

      <Content.Section title={"Our Process"}>
        <Content.Paragraph pr={5}>
          This project included many stakeholders, which are designers,
          developers, business people, and event organizers, to be responsible
          for the app, dashboard, marketing, and prepare the space on the event
          site. Therefore we worked together to finalize the user flow as soon
          as possible to clearly identify tasks for each team. Also, the design
          system was created by the collaboration of all teams to make the
          project identity consistent and reduce miscommunications between
          design and development team.
        </Content.Paragraph>
        <Content.Image
          src={`${ROOT_IMAGE_PATH}/flow-01.png`}
          caption={
            "user flow diagram makes the tasks clear and easy to allocate"
          }
        />
        <Content.Image
          src={`/assets/work/${workId}/design-system.png`}
          caption={
            "design system keeps consistency and reduces miscommunication between design and development team"
          }
          width={"100%"}
        />
      </Content.Section>

      <Content.Section title={"Technical Development"}>
        <Content.Paragraph>
          We separate development tasks into three main parts, which are the
          web-view app, dashboard, and character component, to make it easier to
          allocate and simultaneously work on each part. Becuse of the limited
          time, the ready-to-use product like Firebase is used as the realtime
          database to connect the app and dashboard together.
        </Content.Paragraph>
        <Content.Paragraph>
          <Content.List>
            <Content.ListItem>
              <Text as={"span"} fontWeight={"medium"}>
                Web-View app{" "}
              </Text>
              - Built by ReactJS which is the library that the team is most
              comfortable with.
            </Content.ListItem>
            <Content.ListItem>
              <Text as={"span"} fontWeight={"medium"}>
                Dashboard
              </Text>
              - Because of a tons of animation and interaction, we decided to
              use HTML canvas for better performance and smoother animation.
              PixiJS library is used as the tools to create those animation and
              logic.
            </Content.ListItem>
            <Content.ListItem>
              <Text as={"span"} fontWeight={"medium"}>
                Character component{" "}
              </Text>
              - The animation of each character is created by PixiJS and wrapped
              by React component to make it reuseable in other react apps.
            </Content.ListItem>
          </Content.List>
        </Content.Paragraph>
        <Content.Image
          useSrcSet={true}
          src={`${ROOT_IMAGE_PATH}/tech-diagram/diagram@3x.png`}
          width={"100%"}
        />
      </Content.Section>
      <Content.Section title={"Gallery"}>
        <Content.Image
          src={`/assets/work/${workId}/app-01.gif`}
          width={"100%"}
          caption={"final app"}
        />
        <Content.Image
          src={`/assets/work/${workId}/dashboard.gif`}
          width={["100%", "50%"]}
          height={["auto", "300px"]}
          caption={"dashboard"}
        />

        <Content.Image
          src={`/assets/work/${workId}/final-03.jpg`}
          width={["100%", "50%"]}
          height={["auto", "300px"]}
          caption={"on site atmosphere"}
        />
        <Content.Image
          src={`/assets/work/${workId}/final-01.png`}
          caption={"on site atmosphere"}
        />
      </Content.Section>
      <Content.Section>
        <Content.TextField
          fields={[
            {
              field: "Developer",
              value: ["Tadpol Rachatasumrit", "Roparat Sukapirom"],
            },
            {
              field: "Designer",
              value: ["Yanisa Udomsakphiboon", "Nantanat Thamthonsiri"],
            },
          ]}
        />
      </Content.Section>
    </Content>
  )
}
const Work = () => {
  return (
    <WorkPageLayout
      header={<HeaderSection />}
      fullWidthHeader={true}
      content={<ContentSection />}
      currentWork={workId}
    />
  )
}

export default Work
